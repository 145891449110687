import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import { getStudentProfile, sendEmail, beforeEmail, beforeHeaderCall, getUserNotifications, updateUserNotification } from './action/header.action';
import { getSiteSetting, beforeSiteSettings } from '../../../redux/sharedAction/siteSettings.action';
import { ENV } from './../../../config/config';
import $ from 'jquery';
import './headerMathlete.css'
const cdnBaseUrl = ENV.cdnBaseUrl;
$(document).on("click", ".notification-icon .fa-bell", function () {
	$(".notification-icon").toggleClass('active');
});

const Msg = (props, { closeToast }) => (
	<div className="alert compl-alert">
		<span className="compl-alert-text">{(props.emailverified && props.emailverified !== null) ? "Please complete your profile" : "Please verify your email"}</span>
		{
			props.emailverified ? <button className="btn btn-sm btn-default" onClick={props.goto}>Profile</button> : ''
		}

	</div>
)
class HeaderComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoader: false,
			siteLogo: '',
			token: '',
			userID: '',
			notifications: [],
			profile: {},
			prevScrollpos: window.pageYOffset,
			visible: true,
			mobileCheck: false,
			dynamicPopup: {}
		}
		// let toastId = null;
	}

	componentDidMount() {
		$('body').removeClass('pos-fix');
		let queryParams = this.props.location.search;
		if (queryParams.indexOf("mobileapp") > -1) {
			localStorage.setItem('mobile-app', true)
		}

		if (!localStorage.getItem("userID-site") && (this.props.history.location.pathname.indexOf('/profile') > -1 || this.props.history.location.pathname.indexOf('/deal-redeem-history') > -1 || this.props.history.location.pathname.indexOf('/dashboard') > -1 || this.props.history.location.pathname.indexOf('/quiz') > -1 || this.props.history.location.pathname.indexOf('/update-password') > -1)) {
			this.props.history.push('/signup');
		}
		let mobile = localStorage.getItem("mobile-app")
		if (mobile === 'true') {
			this.setState({
				mobileCheck: true
			})
		}
		if (this.props.history.location.pathname.indexOf('/quiz/') < 0) {
			let currentQuestion = localStorage.getItem("currentQuestionId");
			localStorage.removeItem(currentQuestion);
			localStorage.removeItem("currentQuestionId");
			document.removeEventListener('contextmenu', function () {
				console.log("removed listener");
			});
		}
		else {
			document.addEventListener('contextmenu', event => event.preventDefault());
		}
		let currentPath = this.props.history.location.pathname;
		setTimeout(() => {
			$(`[to="${currentPath}"]`).addClass('active');
		});

		// if (this.props.settings.authGetSettings) {
		//     let data = this.props.settings.getSettingsRes.data;
		//     let thisHere = this;
		//     this.setState({
		//         isLoader: false,
		//         siteLogo: data.siteLogo ? ENV.assetUrl + '/images/' + data.siteLogo : undefined
		//     });
		// }

		window.addEventListener("scroll", this.handleScroll);
		window.scroll(0, 0);

		let token = localStorage.getItem('token-site');
		let user = JSON.parse(localStorage.getItem('userID-site'));

		if (token && user) {
			this.setState({
				token,
				userID: user
			}, () => {
				this.props.getStudentProfile(user);
				this.props.getUserNotifications(user);
			})
		}

		this.setState({ isLoader: true });
		this.props.getSiteSetting();

		$('.navbar-toggler').click(function (e) {
			if ($('#header').hasClass('highZindex')) {
				$('#header').removeClass('highZindex');
			}
			else {
				$('#header').addClass('highZindex');
			}
		});
		$('.navbar-toggler').dblclick(function (e) {
			if ($('#header').hasClass('highZindex')) {
				$('#header').removeClass('highZindex');
			}
			else {
				$('#header').addClass('highZindex');
			}
		});
	}

	handleScroll = () => {
		const $ = window.$;

		const headerHeight = $('#header').innerHeight();
		if (window.pageYOffset >= headerHeight) {
			$('#header').addClass('sticky');
			$('#root').css('paddingTop', headerHeight);
			$('#project-by').addClass('hidden');
		}
		else if (window.pageYOffset < headerHeight) {
			$('#header').removeClass('sticky');
			$('#root').css('paddingTop', 0);
			$('#project-by').removeClass('hidden');
		}
	};

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			$('#header').removeClass('sticky');
			$('#root').css('paddingTop', 0);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (Object.keys(nextProps.errors).length > 0) {
			if (nextProps.errors.valid === false) {
				ENV.clearStorage();
				this.props.history.push('/');
			}
		}
		if (nextProps.header.authGetUserNotifications) {
			let data = nextProps.header.getUserNotifications;
			this.setState({
				notifications: data.notifications,
				notCount: data.count
			})
			this.props.beforeHeaderCall();
		}
		if (nextProps.header.authUpdateNot) {
			let data = nextProps.header.updateNotRes;
			if (data.success === true) {
				this.setState({
					notCount: data.count
				})
				this.props.beforeHeaderCall();
				// this.props.getUserNotifications(this.state.userID)
			}
		}
		if (nextProps.header.authGetStudentProf) {
			let data = nextProps.header.getStudentPtofRes.data;
			if (data !== null) {
				if (data.logoPath !== '' && data.logoPath)
					data.logoPath = data.logoPath.replace('https://mathlete.s3.amazonaws.com', cdnBaseUrl);

				this.setState({
					profile: data
				}, () => {
					this.props.beforeHeaderCall();
					if (data.xOauth === undefined) {
						this.props.history.push('/update-password?change=yes');
					}
				})
			} else {
				this.props.history.push('/logout-and-clear-session');
				window.location.reload();
			}
		}

		if (nextProps.header.authEmailRes) {
			// let message = nextProps.header.getEmailRes.message;
			// this.notifySuccess(message, 'get-email-2-success');
			// this.props.beforeEmail();

			let { profile } = this.state;

			let emailVerificationPath = `/email-verification?user=${profile._id}`;

			if (profile.socialLogin)
				emailVerificationPath += '&email=true';

			this.props.history.push(emailVerificationPath);
		}

		if (nextProps.settings.authGetSettings) {
			let data = nextProps.settings.getSettingsRes.data;
			if (data && data.popup && Object.keys(data.popup).length) {
				this.setState({ dynamicPopup: data.popup });
				setTimeout(() => {
					window.jQuery("#dynamicDataModal").modal('show');
				}, 2000)
			}
			this.setState({
				isLoader: false,
				siteLogo: data ? ENV.assetUrl + '/images/' + data.siteLogo : undefined
			}
				, function () {
					setTimeout(function () {
						let $scrHeight = $(window).innerHeight();
						let $footerHeight = $('.footerHolder').innerHeight();
						let $headerHeight = $('#header').innerHeight();

						$('.bottomGapFixed').css('minHeight', ($scrHeight - ($headerHeight + $footerHeight)));
					})
				});

			this.props.beforeSiteSettings();
		}
	}

	notifyErr = (msg, toastId) => {
		toast.error(`${msg}`, {
			toastId
		});
	}

	notifySuccess = (msg, toastId) => {
		toast.success(`${msg}`, {
			toastId
		});
	}

	goto = () => {
		this.props.history.push('/profile')
	}

	showToast = (emailverified, compName) => {
		if (this.state.token) {
			if (!toast.isActive(this.toastId)) {
				this.toastId = toast(<Msg goto={this.goto} emailverified={emailverified} />, { autoClose: false }, {
					toastId: 'profile1'
				});;
			}
		} else {
			if (compName === 'practice')
				this.props.history.push('/practice')
			else
				this.props.history.push('/contest')
		}
	}

	getNavLinkClass = (path) => {
		return window.location.pathname === path ? 'nav-item show' : 'nav-item';
	}

	logout = () => {
		ENV.clearStorage();
	}

	sendEmail = (id, fullName, email) => {
		let body = {
			id,
			fullName,
			email
		}
		this.props.sendEmail(body)
	}

	onClickNot(id, seen) {
		if (seen === false) {
			$(`#${id}`).removeClass('new-notice');
			this.props.updateUserNotification(id, this.state.userID);
		}
	}

	moveToLogin = () => {
		window.location.href = ENV.baseUrl + "logout-and-clear-session";
		// window.location.href = "http://localhost:3000/logout-and-clear-session";
	}

	navigateToLink(e) {
		let targetUrl = $(e.target).attr("to");
		if (!targetUrl) {
			let elementWithUrl = $(e.target).parents('[to]').first();
			targetUrl = $(elementWithUrl).attr("to");
		}
		localStorage.setItem('quit-target-url', targetUrl);
		if (this.props.location.pathname.indexOf("/quiz/") > -1) {
			window.jQuery("#exitQuizModal").modal('show');
		} else {
			if ($(e.target).attr("reload")) {
				window.location.href = targetUrl;
			}
			else {
				this.props.history.push(targetUrl);
			}
		}
	}

	closeDynamicModal() {
		window.jQuery("#dynamicDataModal").modal('hide');
	}

	render() {
		let { profile, dynamicPopup } = this.state;

		return (
			<React.Fragment>
				<header id="header">
					<div className=" main-menu">
						<div className=" align-items-center justify-content-between d-flex">
							<nav className="navbar navbar-expand-lg navbar-dark" id="mainNav">
								<span className="navbar-brand js-scroll-trigger logo cur-poi" to="/" onClick={(e) => this.navigateToLink(e)}>
									{this.state.siteLogo ?
										<img src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581058959/mathlete/assets/img/mathlete-logo-01_ohsu9z.svg" alt="Mathlete Site Logo" />
										: ''
									}
								</span>
								<button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
									<span className="navbar-toggler-icon" />
								</button>
								{/* {
									this.state.token ?
										<span className="mathpoints-counter mobile-counter" data-toggle="tooltip" data-placement="bottom" title="Math Points">
											<span className="counter">{profile.mathpoints}</span>
											<i className="fa fa-trophy"></i>
										</span>
										:
										''
								} */}
								<div className={`collapse navbar-collapse ${!this.state.mobileCheck ? 'mobileBrowser' : ''}`} id="navbarResponsive">
									<ul className="navbar-nav ml-auto">
										<li className={this.getNavLinkClass("/")}>
											<span className="nav-link js-scroll-trigger" to="/" onClick={(e) => this.navigateToLink(e)}>Home</span>
										</li>
										<li className={this.getNavLinkClass("/campaigns")}>
											<span className="nav-link js-scroll-trigger" to="/campaigns" onClick={(e) => this.navigateToLink(e)}>Campaigns</span>
										</li>
										{/* <li className="nav-item dropdown">
											<span className="nav-link js-scroll-trigger dropdown-toggle cur-poi" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">How to Play?</span>
											<div className="dropdown-menu" aria-labelledby="navbarDropdown">
												<span className="dropdown-item" to="/instruction" onClick={(e)=>this.navigateToLink(e)}>Instructions</span>
												<span className="dropdown-item" to="/timeline" onClick={(e)=>this.navigateToLink(e)}>Contest Timeline</span>
												<span className="dropdown-item" to="/evaluation-criteria" onClick={(e)=>this.navigateToLink(e)}>Evaluation Criteria</span>
											</div>
										</li> */}
										{/* <li className={this.getNavLinkClass("/practice")}>
											{
												(profile.firstName !== '' && profile.fatherName !== undefined && profile.dob !== undefined
													&& profile.instituteName !== undefined && profile.grade !== null) ?
													<span className="nav-link js-scroll-trigger cur-poi" to="/practice" onClick={(e)=>this.navigateToLink(e)}>Practice</span>
													:
													<span className="nav-link js-scroll-trigger cur-poi" onClick={() => this.showToast(true, 'practice')} >Practice</span>
											}
										</li> */}
										{/* <li className={this.getNavLinkClass("/contest")}>
											{
												(profile.firstName !== '' && profile.fatherName !== undefined && profile.dob !== undefined && profile.instituteName !== undefined && profile.city !== undefined && profile.grade !== null) ?
													<span className="nav-link js-scroll-trigger" to="/contest" onClick={(e)=>this.navigateToLink(e)}>Contest</span>
													:
													<span onClick={() => this.showToast(true, 'contest')} className="nav-link js-scroll-trigger cur-poi" >Contest</span>
											}
										</li> */}
										{/* <li className={this.getNavLinkClass("/prize")}>
											<span className="nav-link js-scroll-trigger" to="/prize" onClick={(e)=>this.navigateToLink(e)}>Prize</span>
										</li>
										<li className={this.getNavLinkClass("/top-mathletes")}>
											<span className="nav-link js-scroll-trigger" to="/top-mathletes" onClick={(e)=>this.navigateToLink(e)}>Top Mathletes</span>
										</li>
										<li className={this.getNavLinkClass("/deals")}>
											<span className="nav-link js-scroll-trigger" to="/deals" onClick={(e)=>this.navigateToLink(e)}>Deals</span>
										</li> */}
										<li className={this.getNavLinkClass("/faqs")}>
											<span className="nav-link js-scroll-trigger" to="/faqs" onClick={(e) => this.navigateToLink(e)}>FAQs</span>
										</li>
										<li className={this.getNavLinkClass("/about-us")}>
											<span className="nav-link js-scroll-trigger" to="/about-us" onClick={(e) => this.navigateToLink(e)}>About Us</span>
										</li>
										<li className={this.getNavLinkClass("/contact-us")}>
											<span className="nav-link js-scroll-trigger" to="/contact-us" onClick={(e) => this.navigateToLink(e)}>Contact Us</span>
										</li>
										<li className="nav-item button colr text-uppercase">
											<span className="nav-link js-scroll-trigger" to="/coming-soon" onClick={(e) => this.navigateToLink(e)}>Join MathWIN Program</span>
										</li>
										{/* {
											this.state.token ?
												<li>
													<span className="mathpoints-counter desktop-counter" data-toggle="tooltip" data-placement="bottom" title="Math Points">
														<span className="counter">{profile.mathpoints}</span>
														<i className="fa fa-trophy"></i>
													</span>
												</li>
												:
												''
										} */}
										{/* {
											this.state.token ?
												<React.Fragment>
													<li className="nav-item dropdown profile">
														<span className="nav-link js-scroll-trigger dropdown-toggle cur-poi" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
															<span className="headerProfileImageHolder">
																{
																	(profile && profile.logoPath) ?
																		<img className="img-fluid" id="logoPathHeader" src={profile.logoPath} alt="Logo Path" />
																		:
																		profile.gender === '2' || profile.gender === 2 ?
																			<img className="img-fluid" src={MaleImg} alt="Default logo path" />
																			:
																			profile.gender === '1' || profile.gender === 1 ?
																				<img className="img-fluid" src={FemaleImg} alt="Default logo path" />
																				:
																				<img className="img-fluid" src={DefaultImg} alt="Default logo path" />
																}
															</span>
															{(profile.firstName !== null && profile.firstName !== undefined) ? profile.firstName : ''}
														</span>
														<div className="dropdown-menu" aria-labelledby="navbarDropdown">
															<span className="dropdown-item" to="/profile" onClick={(e)=>this.navigateToLink(e)}>My Profile</span>
															<span className="dropdown-item" to="/dashboard" onClick={(e)=>this.navigateToLink(e)}>My Dashboard</span>
															<span className="dropdown-item" to="/deal-redeem-history" onClick={(e)=>this.navigateToLink(e)}>Deal Redeem History</span>
															<span className="dropdown-item" to="/update-password" onClick={(e)=>this.navigateToLink(e)}>Change Password</span>
															<span to="/logout-and-clear-session" className="dropdown-item" reload="1" onClick={(e)=>this.navigateToLink(e)}>Logout</span>
														</div>
													</li>
												</React.Fragment>
												:
												<React.Fragment>
													{
														this.state.mobileCheck ?
															<React.Fragment>
																<li className="nav-item button colr">
																	<span className="nav-link js-scroll-trigger" onClick={this.moveToLogin}>REGISTER</span>
																</li>
																<li className="nav-item button light">
																	<span className="nav-link js-scroll-trigger" onClick={this.moveToLogin}>LOG IN</span>
																</li>
															</React.Fragment>
															:
															<React.Fragment>
																<li className="nav-item button colr">
																	<NavLink className="nav-link js-scroll-trigger" to="/signup">REGISTER</NavLink>
																</li>
																<li className="nav-item button light">
																	<NavLink className="nav-link js-scroll-trigger" to="/login">LOG IN</NavLink>
																</li>
															</React.Fragment>
													}
												</React.Fragment>
										} */}
									</ul>
								</div>
								{this.state.token ?
									<span className="mathpoints-counter desktop-counter" data-toggle="tooltip" data-placement="bottom" title="Math Points">
										<span className="counter">{profile.items ? profile.items : 0}</span>
										<i className="fa fa-shopping-cart cur-poi" to="/cart" onClick={(e) => this.navigateToLink(e)}></i>
									</span>
									:
									''
								}
							</nav>
						</div>
					</div>
				</header>
				<div id="contestAwareModal" className="modal fade contest mycustommodal">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true"><span>x</span></button>
								<div className="header-image-holder">
									<img className="img-fluid" src='https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581672528/mathlete/assets/img/modal-header_wvzsb6.png' alt="Modal header" />
								</div>
							</div>
							<div className="modal-body">
								<h4 className="modal-title">Welcome Dear <span className="text-blue">{this.state.profile && this.state.profile.firstName ? this.state.profile.firstName : ''}</span>!</h4>
								<p>We would like to thank you for registering with <span className="text-bold">McDonald's MATHmania</span>. You are in Practice Mode where you will be able to acquaint yourself with the format of the quiz, the types of questions, the scoring system and ranking criteria. You will be able to activate your access code for participating in <span className="text-bold">McDonald's MATHmania</span> starting from 10th February. We wish you all the very best for your efforts and success.</p>
							</div>
							<div className="modal-footer text-center">
								<button type="button" className="btn btn-secondary" data-dismiss="modal">Ok, I Understand</button>
							</div>
						</div>
					</div>
				</div>
				<div id="dynamicDataModal" className="modal fade contest mycustommodal" data-backdrop="static">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<button type="button" className="close" data-dismiss="modal" aria-hidden="true"><span>x</span></button>
								<div className="header-image-holder">
									<img className="img-fluid" src='https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581672528/mathlete/assets/img/modal-header_wvzsb6.png' alt="Modal Header" />
								</div>
							</div>
							<div className="modal-body">
								<h4 className="modal-title" dangerouslySetInnerHTML={{ __html: dynamicPopup.title ? dynamicPopup.title : 'No description found' }}></h4>
								<p dangerouslySetInnerHTML={{ __html: dynamicPopup.content ? dynamicPopup.content : 'No description found' }}></p>
							</div>
							<div className="modal-footer text-center">
								{
									dynamicPopup.btnType === 1 &&
									<button type="button" className="btn btn-secondary" onClick={() => this.closeDynamicModal()}>
										{
											dynamicPopup.closeBtnTxt ? dynamicPopup.closeBtnTxt : 'Close'
										}
									</button>
								}
								{
									dynamicPopup.btnType === 2 &&
									<button type="button" className="btn btn-secondary" onClick={() => window.open(`${dynamicPopup.redirectBtn1Link ? dynamicPopup.redirectBtn1Link : '/'}`, "_blank")}>
										{
											dynamicPopup.redirectBtn1Txt ? dynamicPopup.redirectBtn1Txt : 'N/A'
										}
									</button>
								}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

const mapDispatchToProps = {
	getStudentProfile,
	sendEmail, beforeEmail, beforeHeaderCall,
	getSiteSetting, beforeSiteSettings,
	getUserNotifications, updateUserNotification
};

const mapStateToProps = state => ({
	settings: state.siteSettings,
	header: state.header,
	errors: state.errors
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(HeaderComponent));