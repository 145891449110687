import { GET_TOP_MATHLETES, BEFORE_TOP_MATHLETES } from '../../../redux/types';

const initialState = {
    getTopMathletesRes: {},
    topMathletesAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TOP_MATHLETES:
            return {
                ...state,
                getTopMathletesRes: action.payload,
                topMathletesAuth: true
            }
        case BEFORE_TOP_MATHLETES:
            return {
                ...state,
                topMathletesAuth: false
            }
        default:
            return {
                ...state
            }
    }
}