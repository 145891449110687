import { GET_SITE_SETTINGS, BEFORE_SITE_SETTINGS } from '../types'
const initialState = {
    getSettingsRes: {},

    //check point for  API
    authGetSettings: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SITE_SETTINGS:
            return {
                ...state,
                getSettingsRes: action.payload,
                authGetSettings: true,
            }

        case BEFORE_SITE_SETTINGS:
            return {
                ...state,
                authGetSettings: false,
            }
        default:
            return {
                ...state
            }
    }
}