import { GET_STUDENT_PROFILE, GET_USER_NOTIFICATIONS, UPDATE_USER_NOTIFICATION, SEND_EMAIL_RES, EMAIL_VERIFIED, BEFORE_HEADER_CALL, BEFORE_EMAIL } from '../../../../redux/types';

const initialState = {
    getStudentPtofRes: {},
    getEmailRes: {},
    getVerifyEmailRes: {},
    getUserNotifications: {},
    updateNotRes: {},

    //check GET list API
    authGetStudentProf: false,
    authGetUserNotifications: false,
    authUpdateNot: false,
    authEmailRes: false,
    authVerify: false,

}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_STUDENT_PROFILE:
            return {
                ...state,
                getStudentPtofRes: action.payload,
                authGetStudentProf: true
            }
        case GET_USER_NOTIFICATIONS:
            return {
                ...state,
                getUserNotifications: action.payload,
                authGetUserNotifications: true,
            }
        case UPDATE_USER_NOTIFICATION:
            return {
                ...state,
                updateNotRes: action.payload,
                authUpdateNot: true
            }
        case SEND_EMAIL_RES:
            return {
                ...state,
                getEmailRes: action.payload,
                authEmailRes: true
            }
        case BEFORE_EMAIL:
            return {
                ...state,
                authEmailRes: false
            }
        case EMAIL_VERIFIED:
            return {
                ...state,
                getVerifyEmailRes: action.payload,
                authVerify: true
            }
        case BEFORE_HEADER_CALL:
            return {
                ...state,
                authGetStudentProf: false,
                authEmailRes: false,
                authVerify: false,
                authGetUserNotifications: false,
                authUpdateNot: false
            }
        default:
            return {
                ...state
            }
    }
}