import React, { Component } from 'react';
import routes from './routes';
import { PrivateRoute } from './privateRoute';
import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ENV } from './config/config';
import io from 'socket.io-client';
const socket = io(ENV.baseUrl);

toast.configure({
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: false,
  newestOnTop: true,
  autoClose: 3000
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      settings: {}
    }
  }

  componentDidMount() {
    socket.emit("activeUsers", "umer surkhail");

    //logout on change password
    socket.on('changePasswordServer', (data) => {
      let userId = localStorage.getItem("userID-site");
      if(userId && userId === data) {
        window.location.href = "/logout-and-clear-session";
      }
    });
  }

  render() {
    return (
      <Router>
          <Switch>
            {
            routes.map((route, index) => {
              return (
                <PrivateRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  access={route.access !== undefined && route.access === false ? false : true}
                  component={props => (
                    <route.layout {...props}>
                      <route.component  {...props} />
                    </route.layout>
                  )}
                />
              )
            })
          }
          </Switch>
      </Router>
    )
  }
}

export default App;