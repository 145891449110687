import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';
import './footerMathlete.css'

class FooterComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showImageDivider: true,
			settingRes: {},
			footerLinks: true
		};
	}

	componentDidMount() {
		let path = window.location.pathname;
		let mobile = localStorage.getItem('mobile-app')
		if (mobile === 'true') {
			this.setState({
				footerLinks: false
			})
		}
		if (path === "/") {
			this.setState({ showImageDivider: false })
		}
	}

	handleScroll = () => {
		const $ = window.$;

		const headerHeight = $('#header').innerHeight();
		if (window.pageYOffset >= headerHeight) {
			$('.project-by').css('display', 'none');
		}
		else if (window.pageYOffset < headerHeight) {
			$('.project-by').css('display', 'block');
		}
	};

	goToUrl(url) {
		window.open(url, "_system");
	}

	notifyWarning = (msg, toastId) => {
		toast.warning(msg, {
			toastId
		});
	}

	navigateToLink(e) {
		let targetUrl = $(e.target).attr("to");
		if(!targetUrl) {
			let elementWithUrl = $(e.target).parents('[to]').first();
			targetUrl = $(elementWithUrl).attr("to");
		}
		localStorage.setItem('quit-target-url', targetUrl);
		if(this.props.location.pathname.indexOf("/quiz/") > -1) {
			window.jQuery("#exitQuizModal").modal('show');
		} else {
			console.log("else target url");
			if($(e.target).attr("reload")) {
				window.location.href = targetUrl;
			}
			else {
				this.props.history.push(targetUrl);
			}
		}
	}

	render() {
		let { data } = this.props.settings.getSettingsRes;

		return (
			<React.Fragment>
				<footer id="footer">
					<div className="exciting-prizes">
						<div className="heading-section">
							<div className="container">
								<div className="blue">
									<span>Exciting Prizes Awaits You</span>
								</div><br />
								<div className="orange">
									<span>Are you ready?</span>
								</div>
							</div>
						</div>
					</div>
					<div className="prize-section">
						<div className="container">
							<div className="row">
								<div className="col-md-6">
									<div className="footer-image-holder">
										<img src={'https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642574405/mathlete/assets/site-images/footer-image-01_lpuriz.png'} alt="Exciting Prizes Awaits You" />
									</div>
								</div>
								<div className="col-md-6">
									<div className="text-section text-center text-md-right">
										<div className="d-inline-block align-top">
											<span className="prize-text mb-3 d-block text-left">Play Math with
											<br />
											<span className="yellow d-block text-left">Mathlete.pk</span></span>
											<div className="download-app text-left pt-3">
												<h4 className="text-white">Download Mathlete App</h4>
												<div className="buttons">
													{
														(data && data.iosUrl) ?
															<span className="cur-poi" onClick={() => this.goToUrl(data.iosUrl)}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/appstore_n0dygp.png"  alt="Apple Store"/></span>
															:
															<span className="cur-poi" onClick={() => this.notifyWarning('Mathlete\'s iOS app will be available soon', 'iOS-toast')}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/appstore_n0dygp.png"  alt="Download Mathlete App"/></span>
													}

													{
														(data && data.androidUrl) ?
															<span className="cur-poi" onClick={() => this.goToUrl(data.androidUrl)}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/playstore_jiyzze.png"  alt="Google Play"/></span> :
															<span className="cur-poi" onClick={() => this.notifyWarning('Mathlete\'s android app will be available soon', 'android-toast')}> <img className="img-fluid" src="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1581687324/mathlete/assets/img/playstore_jiyzze.png"  alt="Download Mathlete App" /></span>
													}
												</div>
											</div>
											{/* <div className="text-left pt-4">
												<button type="button" className="btn btn-register text-uppercase">Join Mathlete Family</button>
											</div> */}
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="footer-nav-holder">
										<ul className="list-unstyled text-center">
											<li><Link to="/">Home</Link></li>
											<li><Link to="/campaigns">Campaigns</Link></li>
											<li><Link to="/faqs">FAQs</Link></li>
											<li><Link to="/about-us">About Us</Link></li>
											<li><Link to="/contact-us">Contact Us</Link></li>
										</ul>
										<ul className="list-unstyled text-center">
											<li><Link to="/privacy-policy">Privacy Policy</Link></li>
											<li><Link to="/terms-of-service">Terms of Services</Link></li>
										</ul>
									</div>
									<div className="social-links">
										<ul>
											<li>
												{
													(data && data.fbLink) ?
														<span style={{ 'cursor': 'pointer' }} onClick={() => this.goToUrl(data.fbLink)}> <span className="fab fa-facebook-f" /></span>
														:
														<span style={{ 'cursor': 'pointer' }} onClick={() => this.notifyWarning('Note! Facebook link will be available soon', 'fb-toast')}> <span className="fab fa-facebook-f" /></span>
												}
											</li>
											<li>
												{
													(data && data.twitterLink) ?
														<span style={{ 'cursor': 'pointer' }} onClick={() => this.goToUrl(data.twitterLink)}> <span className="fab fa-twitter" /></span>
														:
														<span style={{ 'cursor': 'pointer' }} onClick={() => this.notifyWarning('Note! Twitter link will be available soon', 'tw-toast')}> <span className="fab fa-twitter" /></span>
												}
											</li>
											<li>
												{
													(data && data.linkedInLink) ?
														<span style={{ 'cursor': 'pointer' }} onClick={() => this.goToUrl(data.linkedInLink)}> <span className="fab fa-instagram" /></span>
														:
														<span style={{ 'cursor': 'pointer' }} onClick={() => this.notifyWarning('Note! Instagram link will be available soon', 'tw-toast')}> <span className="fab fa-instagram" /></span>
												}
											</li>
										</ul>
									</div>
									<div className="copyright-text text-center">
										<p>Mathlete.pk &copy; All Rights Reserved </p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-as-icon">
						<a className="footer-as-link" href="https://www.arhamsoft.com" rel="noopener noreferrer" target="_blank">&nbsp;</a>
						<img className="img-fluid" src={"https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1642574402/mathlete/assets/site-images/as-icon-footer_nl2wsx.png"} alt="Footer Icon" />
					</div>
				</footer>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	settings: state.siteSettings,
});

export default connect(
	mapStateToProps,
	null,
)(withRouter(FooterComponent));