import { combineReducers } from 'redux';
import faqReducer from '../components/faqComponent/reducer/faq.reducer';
import contactusReducer from '../components/contactus/reducer/contactus.reducer';
import errorReducer from '../redux/sharedReducer/error.reducer';
import siteSettingsReducer from './sharedReducer/siteSettings.reducer';
import headerReducer from '../shared/components/header/reducer/header.reducer';
import homeReducer from '../components/home/reducer/home.reducer';
import campaignsReducer from '../components/campaigns/reducers/campaigns.reducer';
import testimonialsReducer from '../components/testimonials/reducers/testimonials.reducer';
import topMathletesReducer from '../components/topMathletes/reducers/topMathletes.reducer';
import topPerformersReducer from '../components/home/reducer/home.reducer';

export default combineReducers({
    support: faqReducer,
    contactus: contactusReducer,
    errors: errorReducer,
    siteSettings: siteSettingsReducer,
    header: headerReducer,
    home: homeReducer,
    campaigns: campaignsReducer,
    testimonials: testimonialsReducer,
    topMathletes: topMathletesReducer,
    topPerformers: topPerformersReducer
})