import { GET_ALL_SLIDERS, GET_TOP_PERFORMERS, GET_FINAL_TOP_PERFORMERS, BEFORE_TOP_PERFORMERS, GET_NEWS } from '../../../redux/types';

const initialState = {
    allSlidersRes: {},
    authallSlidersRes: false,

    topPerformersRes: {},
    topPerformersAuth: false,

    news: {},
    authGetNewsRes: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_SLIDERS:
            return {
                ...state,
                allSlidersRes: action.payload,
                authallSlidersRes: true
            }

        case GET_TOP_PERFORMERS:
            return {
                ...state,
                topPerformersRes: action.payload,
                topPerformersAuth: true
            }

        case GET_FINAL_TOP_PERFORMERS:
            return {
                ...state,
                finalTopPerformersRes: action.payload,
                finalTopPerformersAuth: true
            }

        case BEFORE_TOP_PERFORMERS:
            return {
                ...state,
                topPerformersAuth: false,
                authallSlidersRes: false,
                finalTopPerformersAuth: false
            }
            
        case GET_NEWS:
            return {
                ...state,
                news: action.payload.news[0],
                authGetNewsRes: true
            }

        default:
            return {
                ...state
            }
    }
}