import React from 'react'
import DefaultLayout from './layouts/defaultLayout/defaultLayout'
import AuthLayout from './layouts/authLayout/authLayout'

const FaqComponent = React.lazy(() => import('./components/faqComponent/faqComponent'))
const ContactusComponent = React.lazy(() => import('./components/contactus/contactusComponent'))
const AboutUs = React.lazy(() => import('./components/aboutUs/aboutUs'))
const Campaigns = React.lazy(() => import('./components/campaigns/campaigns'))
const PrivacyPolicy = React.lazy(() => import('./components/privacyPolicy/privacyPolicy'))
const TermsOfService = React.lazy(() => import('./components/termsOfService/termsOfService'))
const ErrorComponent = React.lazy(() => import('./shared/components/error/errorComponent'))
const ComingSoonComponent = React.lazy(() => import ('./shared/components/comingsoon/ComingSoonComponent'))
const Home = React.lazy(() => import('./components/home/home'))
const JoinMathWin = React.lazy(() => import('./components/JoinMathWin/JoinMathWin'))

const routes = [
    { path: "/", access: true, exact: true, name: "home", layout: DefaultLayout, component: Home },
    { path: "/campaigns", access: true, exact: true, name: "Campaings", layout: DefaultLayout, component: Campaigns },
    { path: "/faqs", access: true, exact: true, name: "faq", layout: DefaultLayout, component: FaqComponent },
    { path: "/privacy-policy", access: true, exact: true, name: "privacy-policy", layout: DefaultLayout, component: PrivacyPolicy },
    { path: "/terms-of-service", access: true, exact: true, name: "terms-of-service", layout: DefaultLayout, component: TermsOfService },
    { path: "/about-us", access: true, exact: true, name: "about-us", layout: DefaultLayout, component: AboutUs },
    { path: "/contact-us", access: true, exact: true, name: "contact-us", layout: DefaultLayout, component: ContactusComponent },
    { path: "/mathwin", access: true, exact: true, name: "login", layout: AuthLayout, component: JoinMathWin },
    { path: "/coming-soon", access: true, exact: true, name: "Coming Soon", layout: AuthLayout, component: ComingSoonComponent },
    { path: "/*", access: true, exact: true, name: "Erorr Page", layout: AuthLayout, component: ErrorComponent }
]

export default routes