import React, { Component } from 'react'
import HeaderComponent from '../../shared/components/header/headerComponent'
import FooterComponent from '../../shared/components/footer/footerComponent'
class DefaultLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <React.Fragment>
                <HeaderComponent />
                {this.props.children}
                <FooterComponent />
            </React.Fragment>
        )
    }
}

export default DefaultLayout;