import { ADD_QUERY, BEFORE_QUERY, GET_QUERY_PASSCODE } from '../../../redux/types'
const initialState = {
    addQueryRes: {},
    getPassCodeRes: {},

    // check POST API
    authQuery: false,
    passcodeAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_QUERY:
            return {
                ...state,
                addQueryRes: action.payload,
                authQuery: true,
            }
        case GET_QUERY_PASSCODE:
            return {
                ...state,
                getPassCodeRes: action.payload,
                passcodeAuth: true
            }
        case BEFORE_QUERY:
            return {
                addQueryRes: {},
                getPassCodeRes: {},
                authQuery: false,
                passcodeAuth: false
            }
        default:
            return {
                ...state
            }
    }
}