import { GET_STUDENT_PROFILE, UPDATE_USER_NOTIFICATION, GET_USER_NOTIFICATIONS, SEND_EMAIL_RES, EMAIL_VERIFIED, GET_ERRORS, EMPTY_ERRORS, BEFORE_HEADER_CALL, BEFORE_EMAIL } from '../../../../redux/types';
import { ENV } from '../../../../config/config';

export const beforeEmail = () => {
    return {
        type: BEFORE_EMAIL
    }
}

export const beforeHeaderCall = () => {
    return {
        type: BEFORE_HEADER_CALL
    }
}

export const getUserNotifications = (id) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'site-notification/all/user-notifications?id=' + id, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_USER_NOTIFICATIONS,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }

    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}


export const updateUserNotification = (id, userId) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'site-notification/update?id=' + id + '&userId=' + userId, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: UPDATE_USER_NOTIFICATION,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }

    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const getStudentProfile = (id, getBillingAddress = false) => dispatch => {
    dispatch(emptyError());
    let url = ENV.url + 'shared/getStudentProfile?id=' + id
    url += `&getBillingAddress=true`
    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': localStorage.getItem('token-site')
        },
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_STUDENT_PROFILE,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }

    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const sendEmail = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'shared/send/email', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: SEND_EMAIL_RES,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const verifyEmail = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'shared/verify/email', {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: EMAIL_VERIFIED,
                payload: data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }

    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}