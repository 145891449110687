module.exports = {
    ENV: {
        // baseUrl: 'https://www.mathlete.pk/',
        // assetUrl: 'https://www.mathlete.pk',
        // url: 'https://www.mathlete.pk/v1/',

        // baseUrl: 'http://localhost:3000/',
        // assetUrl: 'http://localhost:3000',
        // url: 'http://localhost:3000/v1/',

        baseUrl: 'https://mathletemain.arhamsoft.org/',
        assetUrl: 'https://mathletemain.arhamsoft.org',
        url: 'https://mathletemain.arhamsoft.org/v1/',

        // Headers
        Authorization: 'Bearer U2FsdGVkX19N17nCvW63G3X1nBf6ixXpyzfSyArEw/YCEAbEYSLiaFaQN4p9Sbr3BH8fhcDBZQvhccdKLpCFcB1BfDnzVKe3WfX6bgWRiik=',
        x_auth_token: 'F8KLhlTVO8psuIB',

        clearStorage: function () {
            localStorage.removeItem('token-site');
            localStorage.removeItem('userID-site');
            localStorage.removeItem('userGrade-site');
        },

        // strong password regex
        // new RegExp("^[a-zA-Z0-9]{8,}")
        strongPassword: new RegExp('^.{8,}$'),
        strongPasswordMsg: 'Password must be of at least 8 characters.',

        // cdn base url
        cdnBaseUrl: 'https://d206oo7zkzq77l.cloudfront.net',


        decimalNumberValidator: function (evt) {
            let e = evt || window.event;
            let key = e.keyCode || e.which;
            if ((!e.shiftKey && !e.altKey && !e.ctrlKey) &&
                (((key === 190 || key === 110) ||
                    (key >= 48 && key <= 57)) ||
                    (key >= 96 && key <= 105) ||
                    key === 8 || key === 9 || key === 13 ||
                    key === 35 || key === 36 ||
                    key === 37 || key === 39 ||
                    key === 46 || key === 45)) {
            }
            else {
                e.returnValue = false;
                if (e.preventDefault) e.preventDefault();
            }
        },
    }
}
