import { GET_FAQS } from '../../../redux/types'

const initialState = {
    getFaqsRes: {},

    //check GET list API
    authGetFaqs: false,

}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FAQS:
            return {
                ...state,
                getFaqsRes: action.payload,
                authGetFaqs: true
            }
        default:
            return {
                ...state
            }
    }
}