
import { GET_TESTIMONIALS, BEFORE_TESTIMONIALS} from '../../../redux/types';
const initialState = {
    getTestimonialsRes: {},
    getTestimonialsAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TESTIMONIALS:
            return {
                ...state,
                getTestimonialsRes: action.payload,
                getTestimonialsAuth: true,
            }
        case BEFORE_TESTIMONIALS:
            return {
                ...state,
                getTestimonialsAuth: false
            }    
        default:
            return {
                ...state
            }
    }
}