
import { GET_CAMPAIGNS, GET_CAMPAIGN_DETAILS, BEFORE_CAMPAIGNS} from '../../../redux/types';
const initialState = {
    getCampaignsRes: {},
    getCampaignsAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CAMPAIGNS:
            return {
                ...state,
                getCampaignsRes: action.payload,
                getCampaignsAuth: true,
            }
        case GET_CAMPAIGN_DETAILS:
            return {
                ...state,
                getCampaignDetailsRes: action.payload,
                getCampaignsDetailsAuth: true,
            }
        case BEFORE_CAMPAIGNS:
            return {
                ...state,
                getCampaignsAuth: false,
                getCampaignsDetailsAuth: false
            }    
        default:
            return {
                ...state
            }
    }
}